import React from "react"
import Seo from "../components/seo"
import termsPdf from '../pdfs/Evistamp_Terms of Service v1.0.pdf'
const TermsOfService = () => {

    return (
        <>
         <Seo title="Terms of Service" description="Terms of Service for Evistamp" />
      <div>
        <iframe className="iframe-style" src={termsPdf}/>
        </div>
        </>
    )
}
export default TermsOfService